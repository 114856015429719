import { rotatingBottleImages } from '../shared/content';
import React, { useEffect, useState } from 'react';

type Props = {
  isTopOfPage: boolean;
};

const RotatingBottle = ({ isTopOfPage }: Props) => {
  let [frameCount, setFrameCount] = useState(0);
  let [opacity, setOpacity] = useState(1);
  let [positionY, setPositionY] = useState(0);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY <= 224) {
        setFrameCount(Math.round(window.scrollY / 2));
        setOpacity(1);
        setPositionY(0);
      } else if (window.scrollY <= 300) {
        setFrameCount(112);
        setOpacity(1 - (window.scrollY - 224) / 300);
        setPositionY(window.innerHeight * 0.25 * ((window.scrollY - 224) / 76));
      } else {
        setOpacity(0);
        setPositionY(window.innerHeight);
      }
    });
  });

  useEffect(() => {
    if (window.scrollY > 300) {
      setOpacity(0);
      setPositionY(window.innerHeight);
    }
  }, []);

  return (
    <div
      id="bottle-animation"
      className={`w-full h-full flex-1 items-center fixed top-0 left-0 
      justify-center md:mt-0 mt-[75px] sm:mt-0 z-0
     ${opacity==0?'hidden':'visible'}`}
    >
      <img
        style={{ objectFit: 'cover', opacity: opacity, top: -positionY }}
        className="overflow-hidden flex-1 items-center relative justify-center h-full z-0"
        src={
          rotatingBottleImages[frameCount]
        }
        alt={frameCount.toString()}
      />
    </div>
  );
};

export default RotatingBottle;

import React, { useState, useRef } from 'react';
import Video from '../assets/video.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

const intro='bg-gray-10 flex items-center justify-center w-full h-full mb-10'

const video='h-full w-full z-0 object-cover'


const overlayMute=`absolute bg-black z-10 flex items-center justify-center 
opacity-10 hover:opacity-100 w-[70px] h-[70px]
rounded-full border-1 border-white z-10 cursor-pointer`

const IntroVideo: React.FC = () => {
  const [muteVideo, setMuteVideo] = useState(true)

  const vidRef = useRef<HTMLVideoElement>(null);
  const muteVideoHandle = (): void => {
    if (muteVideo) {
      vidRef.current!.muted = false;
      setMuteVideo(false);
    } else {
        vidRef.current!.muted = true;
      setMuteVideo(true);
    }
  };

  return (
    <div className={intro} id='intro'>
      <div className={video}>
        <video
          src={Video}
          ref={vidRef}
          loop
          muted
          controls={false}
          autoPlay
        />
      </div>
      {/* <div className={overlayPlay}>
        <div className='z-10 ' onClick={playVideoHandle}>
          {playVideo ? (
            <BsPauseFill color='#ffffff' fontSize={30} />
          ) : (
            <BsFillPlayFill color='#ffffff' fontSize={30} />
          )}
        </div>
      </div> */}
      <div className={overlayMute}>
    <div className='z-10' onClick={muteVideoHandle}>
    {muteVideo ? (
        <FontAwesomeIcon icon={faVolumeUp} color='#fff' size="2x" />
      ) : (
        <FontAwesomeIcon icon={faVolumeMute} color='#fff' size="2x" />
      )}
    </div>
</div>
    </div>
  );
};

export default IntroVideo;

import React from 'react';
import Home from '../scenes/Home';
import Benefits from '../scenes/Benefits';
import InContext from '../scenes/InContext';
import Contact from '../scenes/Contact';
import Footer from '../scenes/Footer';
import Features from '../scenes/Features';
import Navbar from '../scenes/Navbar';
import { useEffect, useState } from 'react';
import { SelectedPage } from '../shared/types';
//import { db } from './db/firebase';

type Props = {
};

const Main = ({}: Props) => {
  const [selectedPage, setSelectedPage] = useState<SelectedPage>(
    SelectedPage.Home
  );
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 50) {
        setIsTopOfPage(true);
        setSelectedPage(SelectedPage.Home);
      } else {
        setIsTopOfPage(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    console.log(selectedPage);
  }, [selectedPage]);
  return (
    <div className="app bg-gray-10 z-[-20]">
      <Navbar
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        isTopOfPage={isTopOfPage}
      />
      <Home isTopOfPage={isTopOfPage} setSelectedPage={setSelectedPage} />
      <Benefits setSelectedPage={setSelectedPage} />
      <Features setSelectedPage={setSelectedPage} />
      <InContext setSelectedPage={setSelectedPage} />
      <Contact setSelectedPage={setSelectedPage} />
      <Footer />
    </div>
  );
};

export default Main;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {
  const socialMediaLinks = [
    { name: 'TikTok', url: 'https://www.tiktok.com/@torontotech', icon: faTiktok },
    { name: 'Instagram', url: 'https://www.instagram.com/torontotechgear', icon: faInstagram },
  ];

  return (
    <div className="flex justify-center space-x-4">
      {socialMediaLinks.map((link) => (
        <a
          key={link.name}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
        >
          <FontAwesomeIcon icon={link.icon} className='h-10 w-10 mx-2' />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;

import ActionButton from '../shared/ActionButton';
import PageTitle from '../shared/PageTitle';
import {LogTypeIdEnum, SelectedPage, bottleColors } from '../shared/types';
import {  motion } from 'framer-motion';
import ColorButton from '../shared/ColorButton';
import { useState } from 'react';
import { features } from '../shared/content';
import FeatureCard from '../shared/FeatureCard';
import IntroVideo from './IntroVideo';
import {ShoppingCartIcon
} from '@heroicons/react/24/solid';
import { addToCartLinks, explodedView} from '../shared/content';

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.25 },
  },
};
type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Features = ({ setSelectedPage }: Props) => {
  const [sbottle, setSBottle] = useState<bottleColors>(bottleColors.black);
  const [buttonColor, setButtonColor] = useState<string>("bg-gray-600")
  const [addToCartLink, setAddToCartLink] = useState<string>(addToCartLinks.black)

  const isLaunched = true
  return (
    <section
      id="features"
      className="mx-auto min-h-full w-5/6 pb-20 z-10 bg-white"
    >
      {/* HEADER */}
        <motion.div
        className='z-10'
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.25 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
          onViewportEnter={() => {
            setSelectedPage(SelectedPage.Features);
            console.log('here benefits');
          }}
        >
          <PageTitle>THE ULTIMATE WATER BOTTLE</PageTitle>
          <IntroVideo/>
          {/* <div className="flex items-center justify-center gap-4 ">
            <ColorButton
              onClick={() => {
                setButtonColor("bg-blueBottle")
                setSBottle(bottleColors.blue);
                setAddToCartLink(addToCartLinks.blue)
              }}
              color="bg-blueBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor("bg-gray-400")
                setSBottle(bottleColors.black);
                setAddToCartLink(addToCartLinks.black)
              }}
              color="bg-blackBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor("bg-tealDark")
                setSBottle(bottleColors.teal);
                setAddToCartLink(addToCartLinks.teal)
              }}
              color="bg-tealBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor("bg-pinkBottle")
                setSBottle(bottleColors.pink);
                setAddToCartLink(addToCartLinks.pink)
              }}
              color="bg-pinkBottle"
            />
          </div> */}
          </motion.div>
          {/* FEATURES */}
          <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.05 }}
          transition={{ duration: 1 }}
          variants={container}
          className="grid sm:grid-cols-2 md:grid-cols-3 md:gap-8 sm:gap-4 mt-5 z-10"
        >
            {features.map((f, index) => (
              <FeatureCard key={index} f={f} color={sbottle} />
            ))}
          </motion.div>
       
        {/* GRAPHICS AND DESCRIPTION */}
        <div className="mt-16 items-center flex-col justify-center gap-10 md:mt-28 md:flex">
          {/* GRAPHIC */}
          <div className="flex items-center justify-center gap-4 ">
            <ColorButton
              onClick={() => {
                setButtonColor("bg-blueBottle")
                setSBottle(bottleColors.blue);
                setAddToCartLink(addToCartLinks.blue)
              }}
              color="bg-blueBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor("bg-gray-500")
                setSBottle(bottleColors.black);
                setAddToCartLink(addToCartLinks.black)
              }}
              color="bg-blackBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor("bg-tealDark")
                setSBottle(bottleColors.teal);
                setAddToCartLink(addToCartLinks.teal)
              }}
              color="bg-tealBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor("bg-pinkDark")
                setSBottle(bottleColors.pink);
                setAddToCartLink(addToCartLinks.pink)
              }}
              color="bg-pinkBottle"
            />
          </div>
          <img
          style={{objectFit:'cover'}}
            className="mx-auto w-full"
            alt="benefits-page-graphic"
            src={explodedView[sbottle]}
          />
          {/* DESCRIPTION */}
          <div className='w-full items-center justify-center flex flex-col gap-4 md:mt-0 xs:mt-8'>
            
            {isLaunched?<div>
              <ActionButton actionType={LogTypeIdEnum.addToCart} refProduct={`bottle-${sbottle}`} color={buttonColor} href={addToCartLink} target='_blank'
          icon={  <ShoppingCartIcon style={{display:'inline'}} className='ml-2 h-6 w-6 mb-1'/>}>
              Add to Amazon Cart
            </ActionButton>
            </div>
            :<ActionButton active={false} color={buttonColor} href={addToCartLink} target='_blank'
          icon={  <ShoppingCartIcon style={{display:'inline'}} className='ml-2 h-6 w-6 mb-1'/>}>
              Coming Soon...
            </ActionButton> }
            <div className='flex items-center flex-col'>
            <h2 className='font-bold text-xl text-gray-600'>Starting at $24.99</h2>
            <h2 className='text-gray-600'>100+ people bought last week</h2>
            </div>
            </div>
        </div>
    </section>
  );
};

export default Features;

import { PlanType } from "./types";

export const CarrouselItem = ({ name, description, image }: PlanType) => {
    const overlayStyles = `p-5 absolute z-20 flex h-[400px] w-[400px] flex-col 
      items-center justify-center whitespace-normal bg-primary-500 text-center text-white opacity-0 transition duration-500 hover:opacity-90`;
    return (
      <li className="relative mx-5 inline-block h-[400px] w-[400px] hover:cursor-pointer">
        {/* <div className={overlayStyles}>
          <p className="font-bold text-2xl">{name}</p>
  
          <p className='mt-5'>{description}</p>
        </div> */}
        <img className="h-[400px] object-cover" alt={`${image}`} src={image} />
      </li>
    );
  };
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { LogType, LogTypeIdEnum } from './shared/types';
import axios from 'axios'


const queryParameters = new URLSearchParams(window.location.search);

  const data: LogType = {
    type: LogTypeIdEnum.loadPage,
    createdAt: new Date(),
    source: queryParameters.get('source') || null,
    ad: queryParameters.get('ad') || null,
    refDate: queryParameters.get('refDate' || null),
    refProduct:null
  };

  axios.post('/firebase/loads',{...data})
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

import { BenefitType, PlanType } from './types';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import image4 from '../assets/image4.png';
import image5 from '../assets/image5.png';
import image6 from '../assets/image6.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPersonRunning,
  faCarrot,
  faFaceSmileWink,
  faBrain,
  faHeartCirclePlus,
} from '@fortawesome/free-solid-svg-icons';

import f1Teal from '../assets/features/drinking-teal.png';
import f1Blue from '../assets/features/drinking-blue.png';
import f1Black from '../assets/features/drinking-black.png';
import f1Pink from '../assets/features/drinking-pink.png';

import f2Teal from '../assets/features/handheld-teal.png';
import f2Blue from '../assets/features/handheld-blue.png';
import f2Black from '../assets/features/handheld-black.png';
import f2Pink from '../assets/features/handheld-pink.png';

import f3Teal from '../assets/features/markings-teal.png';
import f3Blue from '../assets/features/markings-blue.png';
import f3Black from '../assets/features/markings-black.png';
import f3Pink from '../assets/features/markings-pink.png';

import f4Teal from '../assets/features/bottom-teal.png';
import f4Blue from '../assets/features/bottom-blue.png';
import f4Black from '../assets/features/bottom-black.png';
import f4Pink from '../assets/features/bottom-pink.png';

import f5Teal from '../assets/features/filter-teal-min.png'
import f5Blue from '../assets/features/filter-blue-min.png'
import f5Black from '../assets/features/filter-black-min.png'
import f5Pink from '../assets/features/filter-pink-min.png'

import f6Teal from '../assets/features/straw-teal.png';
import f6Blue from '../assets/features/straw-blue.png';
import f6Black from '../assets/features/straw-black.png';
import f6Pink from '../assets/features/straw-pink.png';

import evTeal from '../assets/features/exploded-teal.png';
import evBlue from '../assets/features/exploded-blue.png';
import evBlack from '../assets/features/exploded-black.png';
import evPink from '../assets/features/exploded-pink.png';

import img0 from '../assets/bottle-turning/img00.png';
import img1 from '../assets/bottle-turning/img01.png';
import img2 from '../assets/bottle-turning/img02.png';
import img3 from '../assets/bottle-turning/img03.png';
import img4 from '../assets/bottle-turning/img04.png';
import img5 from '../assets/bottle-turning/img05.png';
import img6 from '../assets/bottle-turning/img06.png';
import img7 from '../assets/bottle-turning/img07.png';
import img8 from '../assets/bottle-turning/img08.png';
import img9 from '../assets/bottle-turning/img09.png';
import img10 from '../assets/bottle-turning/img010.png';
import img11 from '../assets/bottle-turning/img011.png';
import img12 from '../assets/bottle-turning/img012.png';
import img13 from '../assets/bottle-turning/img013.png';
import img14 from '../assets/bottle-turning/img014.png';
import img15 from '../assets/bottle-turning/img015.png';
import img16 from '../assets/bottle-turning/img016.png';
import img17 from '../assets/bottle-turning/img017.png';
import img18 from '../assets/bottle-turning/img018.png';
import img19 from '../assets/bottle-turning/img019.png';
import img20 from '../assets/bottle-turning/img020.png';
import img21 from '../assets/bottle-turning/img021.png';
import img22 from '../assets/bottle-turning/img022.png';
import img23 from '../assets/bottle-turning/img023.png';
import img24 from '../assets/bottle-turning/img024.png';
import img25 from '../assets/bottle-turning/img025.png';
import img26 from '../assets/bottle-turning/img026.png';
import img27 from '../assets/bottle-turning/img027.png';
import img28 from '../assets/bottle-turning/img028.png';
import img29 from '../assets/bottle-turning/img029.png';
import img30 from '../assets/bottle-turning/img030.png';
import img31 from '../assets/bottle-turning/img031.png';
import img32 from '../assets/bottle-turning/img032.png';
import img33 from '../assets/bottle-turning/img033.png';
import img34 from '../assets/bottle-turning/img034.png';
import img35 from '../assets/bottle-turning/img035.png';
import img36 from '../assets/bottle-turning/img036.png';
import img37 from '../assets/bottle-turning/img037.png';
import img38 from '../assets/bottle-turning/img038.png';
import img39 from '../assets/bottle-turning/img039.png';
import img40 from '../assets/bottle-turning/img040.png';
import img41 from '../assets/bottle-turning/img041.png';
import img42 from '../assets/bottle-turning/img042.png';
import img43 from '../assets/bottle-turning/img043.png';
import img44 from '../assets/bottle-turning/img044.png';
import img45 from '../assets/bottle-turning/img045.png';
import img46 from '../assets/bottle-turning/img046.png';
import img47 from '../assets/bottle-turning/img047.png';
import img48 from '../assets/bottle-turning/img048.png';
import img49 from '../assets/bottle-turning/img049.png';
import img50 from '../assets/bottle-turning/img050.png';
import img51 from '../assets/bottle-turning/img051.png';
import img52 from '../assets/bottle-turning/img052.png';
import img53 from '../assets/bottle-turning/img053.png';
import img54 from '../assets/bottle-turning/img054.png';
import img55 from '../assets/bottle-turning/img055.png';
import img56 from '../assets/bottle-turning/img056.png';
import img57 from '../assets/bottle-turning/img057.png';
import img58 from '../assets/bottle-turning/img058.png';
import img59 from '../assets/bottle-turning/img059.png';
import img60 from '../assets/bottle-turning/img060.png';
import img61 from '../assets/bottle-turning/img061.png';
import img62 from '../assets/bottle-turning/img062.png';
import img63 from '../assets/bottle-turning/img063.png';
import img64 from '../assets/bottle-turning/img064.png';
import img65 from '../assets/bottle-turning/img065.png';
import img66 from '../assets/bottle-turning/img066.png';
import img67 from '../assets/bottle-turning/img067.png';
import img68 from '../assets/bottle-turning/img068.png';
import img69 from '../assets/bottle-turning/img069.png';
import img70 from '../assets/bottle-turning/img070.png';
import img71 from '../assets/bottle-turning/img071.png';
import img72 from '../assets/bottle-turning/img072.png';
import img73 from '../assets/bottle-turning/img073.png';
import img74 from '../assets/bottle-turning/img074.png';
import img75 from '../assets/bottle-turning/img075.png';
import img76 from '../assets/bottle-turning/img076.png';
import img77 from '../assets/bottle-turning/img077.png';
import img78 from '../assets/bottle-turning/img078.png';
import img79 from '../assets/bottle-turning/img079.png';
import img80 from '../assets/bottle-turning/img080.png';
import img81 from '../assets/bottle-turning/img081.png';
import img82 from '../assets/bottle-turning/img082.png';
import img83 from '../assets/bottle-turning/img083.png';
import img84 from '../assets/bottle-turning/img084.png';
import img85 from '../assets/bottle-turning/img085.png';
import img86 from '../assets/bottle-turning/img086.png';
import img87 from '../assets/bottle-turning/img087.png';
import img88 from '../assets/bottle-turning/img088.png';
import img89 from '../assets/bottle-turning/img089.png';
import img90 from '../assets/bottle-turning/img090.png';
import img91 from '../assets/bottle-turning/img091.png';
import img92 from '../assets/bottle-turning/img092.png';
import img93 from '../assets/bottle-turning/img093.png';
import img94 from '../assets/bottle-turning/img094.png';
import img95 from '../assets/bottle-turning/img095.png';
import img96 from '../assets/bottle-turning/img096.png';
import img97 from '../assets/bottle-turning/img097.png';
import img98 from '../assets/bottle-turning/img098.png';
import img99 from '../assets/bottle-turning/img099.png';
import img100 from '../assets/bottle-turning/img0100.png';
import img101 from '../assets/bottle-turning/img0101.png';
import img102 from '../assets/bottle-turning/img0102.png';
import img103 from '../assets/bottle-turning/img0103.png';
import img104 from '../assets/bottle-turning/img0104.png';
import img105 from '../assets/bottle-turning/img0105.png';
import img106 from '../assets/bottle-turning/img0106.png';
import img107 from '../assets/bottle-turning/img0107.png';
import img108 from '../assets/bottle-turning/img0108.png';
import img109 from '../assets/bottle-turning/img0109.png';
import img110 from '../assets/bottle-turning/img0110.png';
import img111 from '../assets/bottle-turning/img0111.png';
import img112 from '../assets/bottle-turning/img0112.png';

import ic2 from '../assets/in-context/img2.png'
  import ic1 from '../assets/in-context/img1v2.jpg'
  import ic3 from '../assets/in-context/img3-min.jpg'
  import ic4 from '../assets/in-context/img4-min.png'
  import ic5 from '../assets/in-context/img5-min.jpg'
  import ic6 from '../assets/in-context/img6.jpg'
  import ic7 from '../assets/in-context/img7-min.jpg'
  import ic8 from '../assets/in-context/img8-min.png'
  import ic9 from '../assets/in-context/img9-min.jpg'
  import ic12 from '../assets/in-context/img12-min.jpg'
  import ic13 from '../assets/in-context/img13-min.jpg'
  import ic18 from '../assets/in-context/img18-min.png'
  import ic20 from '../assets/in-context/IMG_1844-min.png'
  import ic21 from '../assets/in-context/IMG_1850-min.jpg'
  import ic22 from '../assets/in-context/IMG_1872-min.jpg'
  import ic23 from '../assets/in-context/IMG_1904-min.jpg'
  import ic25 from '../assets/in-context/IMG_1998-min.jpg'
  import ic26 from '../assets/in-context/IMG_2028-min.jpg'
  import ic27 from '../assets/in-context/IMG_2032-min.jpg'
  import ic28 from '../assets/in-context/IMG_2044-min.jpg'
  import ic29 from '../assets/in-context/IMG_2050-min.jpg'
  import ic30 from '../assets/in-context/IMG_2054-min.jpg'

export const rotatingBottleImages = [
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img73,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  img88,
  img89,
  img90,
  img91,
  img92,
  img93,
  img94,
  img95,
  img96,
  img97,
  img98,
  img99,
  img100,
  img101,
  img102,
  img103,
  img104,
  img105,
  img106,
  img107,
  img108,
  img109,
  img110,
  img111,
  img112,
];

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
};

export const features = [
  {
    title: 'Drink the Right Amount Always',
    description:
      ['To ensure you get the exact amount your body needs every single day, our bottle has a capacity of 0.5 gallons / 2 litres.'],
    image: {
      teal: f1Teal,
      blue: f1Blue,
      black: f1Black,
      pink: f1Pink,
    },
  },
  {
    title: 'Take it Anywhere You Go',
    description:
      ['Our bottle can easily fit in any backpack or purse, so you can drink water consistently anywhere you go.'],
    image: {
      teal: f2Teal,
      blue: f2Blue,
      black: f2Black,
      pink: f2Pink,
    },
  },
  {
    title: 'Keep Track and Stay Motivated',
    description: [`Our bottle has time and quantity markings, so you can keep track of your water intake.`,`

    It also has inspiring messages to keep you motivated.
    
    Because what gets measured, gets done!`],
    image: {
      teal: f3Teal,
      blue: f3Blue,
      black: f3Black,
      pink: f3Pink,
    },
  },
  {
    title: 'Peace of Mind. Food Grade, BPA-Free',
    description: [`Our bottle is made of the highest quality silicone - the same used to make baby pacifiers, and it does not contain Bisphenol-A.

    `,`It can sustain temperatures of up to 212 degrees F, while staying food safe. This has been tested to FDA standards.`],
    image: {
      teal: f4Teal,
      blue: f4Blue,
      black: f4Black,
      pink: f4Pink,
    },
  },
  {
    title: 'Filtered Clean Water. Safe to Drink',
    description: [`Our built-in carbon filter eliminates VOCs, heavy metals, herbicides and pesticides, and chlorine up to 99%.`,`

    The filter can be replaced for continuous performance. Like most filters, it is recommended to replace it every 30 days.`],
    image: {
      teal: f5Teal,
      blue: f5Blue,
      black: f5Black,
      pink: f5Pink
    },
  },
  {
    title: 'Comfortable to Drink From',
    description:
      ['The straw allows you to drink more easily. The locking mechanism on the lid allows it to stay open, so you can sip effortlessly.'],
    image: {
      teal: f6Teal,
      blue: f6Blue,
      black: f6Black,
      pink: f6Pink,
    },
  },
];

export const benefits: Array<BenefitType> = [
  {
    icon: <FontAwesomeIcon icon={faPersonRunning} size="3x" />,
    title: 'Enhanced Physical Performance',
    description:
      'When we drink enough water, our bodies are properly hydrated, allowing us to perform physical activities more efficiently. Whether it is exercising, playing sports, or simply going about our daily tasks, optimal hydration supports our muscles, joints, and overall physical endurance.',
  },
  {
    icon: <FontAwesomeIcon icon={faCarrot} size="3x" />,
    title: 'Improved Digestion and Nutrient Absorption',
    description:
      ' Sufficient water intake aids in proper digestion, preventing constipation and promoting regular bowel movements. It also helps our bodies absorb essential nutrients from the food we consume, ensuring that we derive maximum benefits from our diets',
  },
  {
    icon: <FontAwesomeIcon icon={faFaceSmileWink} size="3x" />,
    title: 'Healthier Skin',
    description:
      'Hydration plays a crucial role in maintaining healthy skin. Ample water consumption helps flush out toxins, improves blood circulation, and promotes the elasticity and suppleness of our skin, contributing to a youthful and radiant appearance',
  },
  {
    icon: <FontAwesomeIcon icon={faBrain} size="3x" />,
    title: 'Enhanced Cognitive Function',
    description:
      'Drinking enough water supports optimal brain function. Proper hydration improves concentration, memory, and overall cognitive performance. By keeping our brains hydrated, we can think more clearly, make better decisions, and stay mentally sharp',
  },
  {
    icon: <FontAwesomeIcon icon={faHeartCirclePlus} size="3x" />,
    title: 'Improved Mood and Mental Well-being',
    description:
      'Hydration has a direct impact on our mental health. Staying hydrated can boost our mood, alleviate anxiety, and enhance our overall sense of well-being. By taking care of our hydration needs, we create a positive foundation for mental and emotional resilience.',
  },
];


export const addToCartLinks = {
  black:
    'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B0C16J4Y6T&Quantity.1=1',
  blue: 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B0C16KV8XS&Quantity.1=1',
  teal: 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B0C16HSDPK&Quantity.1=1',
  pink: 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B0C16KQYSB&Quantity.1=1',
};

export const amazonListingLink = 'https://www.amazon.com/dp/B0C6NVDJQD';
export const articleLink =
  'https://to-tech.ca/#/health-risks-benefits-from-drinking-enough-water';

export const inContextImages = [
  ic1,
  ic2,
  ic3,
  ic4,
  ic5,
  ic26,
  ic6,
  ic30,
  ic7,
  ic8,
  ic9,
  ic12,
  ic13,
  ic18,
  ic20,
  ic21,
  ic22,
  ic23,
  ic25,
  ic27,
  ic28,
  ic29
];

import { amazonListingLink, articleLink } from '../shared/content';

import TOTechLogo from '../assets/TORONTOTECH_LOGO_WHITEBG-15.png'


const Footer = () => {
  return (
    <footer className="bg-primary-100 py-16">
      <div className="justify-content mx-auto w-5/6 gap-16 md:flex">
        <div className="mt-16 basis-1/2 md:mt-0">
          <img className="h-12" alt="logo-foot" src={TOTechLogo} />
          <p className="my-5">
            We believe technology and purposeful products can improve people's
            relationships, fitness, wellbeing, and career, and we want to make
            them available to everyone.
          </p>
          <p>© TO Tech All Rights Reserved</p>
        </div>
        <div className="mt-16 basis-1/4 md:mt-0">
          <h4 className="font-bold">Links</h4>
          <a href={articleLink} target='_blank' className="my-5 flex hover:text-secondary-500 ">Why Drinking Water is Important </a>
          <a href='https://to-tech.ca/' className="my-5 flex hover:text-secondary-500 ">TO Tech Company Website</a>
          <a className='hover:text-secondary-500 ' href={amazonListingLink} >Amazon Page</a>
        </div>
        <div className="mt-16 basis-1/4 md:mt-0">
          <h4 className="font-bold">Get in touch</h4>
          <p className="my-5">info@to-tech.ca</p>
          <p className="my-5">Mon-Fri 8:00 - 17:00</p>
          <p>Canada Eastern Time</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import {  useState } from 'react';
import {
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import Logo from '../assets/Logo.png';
import Link from './Link';
import { LogTypeIdEnum, SelectedPage } from '../shared/types';
import useMediaQuery from '../hooks/useMediaQuery';
import ActionButton from '../shared/ActionButton';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { articleLink, amazonListingLink } from '../shared/content';

type Props = {
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  isTopOfPage: boolean;
};

const Navbar = ({ selectedPage, setSelectedPage, isTopOfPage }: Props) => {
  const flexBetween = 'flex items-center justify-between';
  const isAboveMediumScreens = useMediaQuery('(min-width:1256px)');
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const navbarBackground = isTopOfPage ? '' : 'bg-gray-10 drop-shadow';

  const isLaunched = true

  return (
    <nav>
      <div
        className={`${navbarBackground} ${flexBetween} fixed top-0 z-30 w-full py-6`}
      >
        <div className={`${flexBetween} mx-auto w-5/6`}>
          <div className={`${flexBetween} w-full gap-4`}>
            {/* LEFT SIDE OF NAVBAR OR LOGO*/}
            <AnchorLink
              className=""
              href="#home"
              onClick={() => setSelectedPage(SelectedPage.Home)}
            >
              <img
                alt="logo"
                src={Logo}
                style={{ height: 100, objectFit: 'contain' }}
              />
            </AnchorLink>

            {/* RIGHT SIDE OF NAVBAR */}
            {isAboveMediumScreens ? (
              <div className={`${flexBetween} w-full`}>
                <div className={`${flexBetween} gap-6 text-lg`}>
                  <Link
                    page="Home"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <Link
                    page="Features"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <Link
                    title="In Context"
                    page="inContext"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <Link
                    title="Contact"
                    page="Contact"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                </div>
                <div className={`${flexBetween} gap-4`}>
                  <div className="flex items-center justify-center hover:text-secondary-400">
                    <a target="_blank" href={articleLink} className="text-lg ">
                      Why Drinking Water is Important?
                    </a>
                    <ArrowTopRightOnSquareIcon className="ml-1 h-6 w-6 mb-1" />
                  </div>
                  {isLaunched?<ActionButton
                    href={amazonListingLink}
                    target="_blank"
                    actionType ={LogTypeIdEnum.buyOnAmazon}
                  >
                    Buy on Amazon
                  </ActionButton> :<ActionButton
                    href={amazonListingLink}
                    target="_blank"
                    active={false}
                  >
                    Coming Soon...
                  </ActionButton> }
                  
                </div>
              </div>
            ) : (
              <div>
                <button
                  className="rounded-full bg-black p-2 hover:bg-secondary-400 "
                  onClick={() => {
                    setIsMenuToggled(!isMenuToggled);
                  }}
                >
                  <Bars3Icon className="h-6 w-6 text-white" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* MOBILE MENU */}
      {!isAboveMediumScreens && isMenuToggled && (
        <div className="fixed right-0 bottom-0 z-40 h-full w-[300px] bg-secondary-500 drop-shadow-xl">
          <div className="flex justify-end w-full p-10">
            <button
              onClick={() => {
                setIsMenuToggled(!isMenuToggled);
              }}
            >
              {
                <XMarkIcon className="h-8 w-8 text-white hover:text-secondary-400" />
              }
            </button>
          </div>
          <div className={`flex ml-[20%] flex-col  gap-8 text-2xl text-white`}>
            <Link
              page="Home"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="Features"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              title="In Context"
              page="inContext"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              title="Contact"
              page="Contact"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            {isLaunched? <a
            href={amazonListingLink} target='_blank'
              className={`
      } transition duration-500 hover:text-secondary-400 font-bold`}
            >
              Buy on Amazon
            </a>:<div/>}
           
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import {SelectedPage } from '../shared/types';
import { motion } from 'framer-motion';

import PageTitle from '../shared/PageTitle';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRef } from 'react';
import {CarrouselItem} from '../shared/CrrouselItem'
import { inContextImages } from '../shared/content';
import SocialMediaIcons from '../shared/SocialMediaIcons';

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const InContext = ({ setSelectedPage }: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  return (
    <div>
    <section
      id='incontext'
      className="mx-auto  w-full bg-primary-100 justify-center flex items-center"
    >
      {' '}
      <div className=" relative -bottom-[150px] hover:cursor-pointer z-20">
        <ChevronLeftIcon
          className="h-10 w-10  hover:text-primary-300"
          onClick={() => {
            if (buttonRef.current) {
              buttonRef.current.scrollLeft -= 400;
            }
          }}
        />
      </div>
      <motion.div
        className="w-5/6"
        onViewportEnter={() => {
          console.log('here plans')
          setSelectedPage(SelectedPage.InContext)}}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="md:w-3/5">
            <PageTitle>IN CONTEXT</PageTitle>
            <p className="py-5 ">
              Still thinking about it? Look at how other people are using
              our bottle.
            </p>
          </div>
        </motion.div>
        <div
          ref={buttonRef}
          className="mt-10 h-[400px] w-full overflow-x-auto overflow-y-hidden no-scrollbar"
        >
          <ul className={`w-[${(400*inContextImages.length).toString()}px] whitespace-nowrap`}>
            {inContextImages.map((item, index) => (
              <CarrouselItem
                key={index}
                name={item}
                //description={item.description}
                image={item}
              />
            ))}
          </ul>
        </div>
      </motion.div>
      <div className="relative -bottom-[150px] z-20">
        <ChevronRightIcon
          className=" hover:text-primary-300 hover:cursor-pointer  h-10 w-10"
          onClick={() => {
            if (buttonRef.current) {
              buttonRef.current.scrollLeft += 200;
            }
          }}
        />
      </div>
    </section>
    <div className='mt-20 items-center flex-column text-center'>
      <PageTitle>Follow Us </PageTitle>
      <SocialMediaIcons/>
    </div>
    </div>
  );
};

export default InContext;

import React, { ReactComponentElement } from 'react';
import { LogType, LogTypeIdEnum } from './types';
import axios from 'axios';


type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  color?: string;
  icon?: ReactComponentElement<any>;
  href?: string;
  target?: string;
  active?: boolean;
  actionType?: LogTypeIdEnum;
  refProduct?: string;
};

const ActionButton = ({
  children,
  icon,
  onClick = () => {},
  color,
  href,
  target,
  active,
  actionType,
  refProduct,
}: Props) => {
  if (active === false) {
    return (
      <button
        className={` flex-1 items-center justify-center rounded-md ${
          color ? color : 'bg-secondary-500'
        } min-w-[165px] max-w-[250px] text-center px-6 py-3 hover:bg-black ${
          color ? 'text-white' : 'hover:text-white'
        } hover:cursor-pointer z-10`}
      >
        {children}
        {icon}
      </button>
    );
  }

  const onClickLog = () => {
    if (actionType) {
      const queryParameters = new URLSearchParams(window.location.search);
      const data: LogType = {
        type: actionType,
        createdAt: new Date(),
        source: queryParameters.get('source') || null,
        ad: queryParameters.get('ad') || null,
        refDate: queryParameters.get('refDate' || null),
        refProduct: refProduct || null,
      };

      axios.post('/firebase/clicks', {
        ...data,
      });
    }

    onClick();
  };
  return (
    <a
      className={` flex-1 items-center justify-center rounded-md ${
        color ? color : 'bg-secondary-500'
      } min-w-[165px] max-w-[250px] text-center px-6 py-3 hover:bg-black ${
        color ? 'text-white' : 'hover:text-white'
      } hover:cursor-pointer z-10`}
      onClick={onClickLog}
      href={href}
      target={target}
    >
      {children}
      {icon}
    </a>
  );
};

export default ActionButton;

import React from 'react';
import { bottleColors } from './types';
import PageTitle from './PageTitle';
import { motion} from 'framer-motion';
type Props = {
  color: bottleColors;
  f: any;
};

const childVariant = {
  hidden: { opacity: 0, scale: 0.9, x:-70 },
  visible: { opacity: 1, scale: 1,x:0 },
};

const FeatureCard = ({ color, f }: Props) => {

  return (
    <motion.div variants={childVariant} className="flex flex-col z-10">
      <div className='min-h-[150px]'><PageTitle>{f.title}</PageTitle>
      </div>
      <img
        className="w-full"
        alt={f.title + color}
        src={f.image[color]}
      />
      {f.description.map((paragraph:string)=>(<p className="mt-5 text-lg">{paragraph}</p>))}
      
    </motion.div>
  );
};

export default FeatureCard;

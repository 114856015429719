export enum SelectedPage {
  Home = 'home',
  Features = 'features',
  InContext = 'incontext',
  Contact = 'contact',
  Benefits = 'benefits'
}

export interface BenefitType {
  icon: JSX.Element;
  title: string;
  description: string
}

export interface PlanType{
  name:string;
  description?:string;
  image:string;
}


export enum bottleColors {
  teal = 'teal',
  blue='blue',
  black='black',
  pink='pink'
}

export enum  LogTypeIdEnum {
  addToCart = 'add-to-cart',
  loadPage = 'load-page',
  buyOnAmazon = 'buy-on-amazon'
}



export type LogType = {
  type: LogTypeIdEnum;
  createdAt: Date;
  source: String | null;
  ad:String | null;
  refDate:String | null;
  refProduct:String | null
}

import ActionButton from '../shared/ActionButton';
import Benefit from '../shared/Benefit';
import PageTitle from '../shared/PageTitle';
import {SelectedPage } from '../shared/types';
import {
  ArrowTopRightOnSquareIcon
} from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import TealBottleHand from '../assets/teal-handheld.png'
import { articleLink, benefits } from '../shared/content';


const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.25 },
  },
};

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Benefits = ({ setSelectedPage }: Props) => {

  return (
    <section
      id="benefits"
      className="mx-auto min-h-full w-5/6 pt-20 pb-20 z-10 bg-white"
    >
      {/* HEADER */}
      <div>
        {/* GRAPHICS AND DESCRIPTION */}
        <div className="mt-16 items-center justify-between gap-20 md:mt-28 md:flex mb-10 ">
          {/* GRAPHIC */}
          <img
            className="mx-auto flex-1 md:w-1/2"
            alt="benefits-page-graphic"
            src={TealBottleHand}
            style={{objectFit:'contain'}}
          />
          {/* DESCRIPTION */}
          <div>
            {/* TITLE */}
            <div className="relative">
              <div className="before:absolute before:-top-20 before:-lef-20 before:z-[1] before:content-abstractwaves">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.1, duration: 2 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <PageTitle>
                    THE BENEFITS OF{' '}<span className="text-primary-500">HYDRATION</span>{' '}
                  </PageTitle>
                </motion.div>
              </div>
            </div>
            {/* DESCRIPTION */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
              }}
              className='hidden sm:block'
            >
              <p className="my-5 text-lg">
                Hydration is crucial for maintaining good health. Water has a vital role in various bodily functions.
                In contrast, inadequate hydration may result in unclear thinking, fatigue,
                mood changes, reduced kidney functions, and other health risks.
              </p>
              <p className="mb-5 text-lg">
                At Toronto Tech, we want to help you (and everyone) achieve a
                healthier lifestyle. This is why we are offering this water
                bottle which will help you meet your daily hydration needs,
                anywhere you go.
              </p>
            </motion.div>
            {/* BUTTON */}
            <div className="relative mt-12 flex-1">
                <ActionButton href={articleLink} target='_blank' icon={  <ArrowTopRightOnSquareIcon style={{display:'inline'}} className='ml-4 h-6 w-6 mb-1'/>} onClick={() => {}}>Read the Article</ActionButton> 
            </div>
          </div>
        </div>
        
        {/* BENFITS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.25 }}
          transition={{ duration: 1 }}
          variants={container}
          className="grid sm:grid-cols-2 md:grid-cols-3 md:gap-8 sm:gap-4 mt-5 z-10"
        >
          {benefits.map((benefit) => (
            <Benefit
              key={benefit.title}
              title={benefit.title}
              setSelectedPage={setSelectedPage}
              icon={benefit.icon}
              description={benefit.description}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Benefits;

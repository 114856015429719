
import ActionButton from '../shared/ActionButton';
import { LogTypeIdEnum, SelectedPage } from '../shared/types';

import HomePageTextw from '../assets/HomePageText-ws.png';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { motion } from 'framer-motion';
import RotatingBottle from './RotatingBottle';
import { amazonListingLink } from '../shared/content';

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
  isTopOfPage: boolean;
};

const isLaunched = true;

const Home = ({ setSelectedPage, isTopOfPage }: Props) => {
  return (
    <section
      style={{ display: 'flex' }}
      id="home"
      className="gap-16 bg-gray-10 pt-10 h-full md:mb-0 items-center flex-1 justify-center z-10 relative w-full overflow-hidden"
    >
      {/* IMAGE AND MAIN HEADER */}

      <motion.div
        className="md:flex mx-auto w-5/6 items-center justify-center md:h-5/6"
        onViewportEnter={() => setSelectedPage(SelectedPage.Home)}
      >
        {/* MAIN HEADER */}
        <RotatingBottle isTopOfPage={isTopOfPage} />
        <div className="  md:basis-5/6 md:px-20 sm:basis-1">
          {/* HEADINGS */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            className="md:-mt-20"
          >
            <div className="relative ">
              <img alt="home-page-text" src={HomePageTextw} />
            </div>
          </motion.div>

          {/* ACTIONS */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
            className="flex items-center gap-x-6 gap-y-4 justify-center md:justify-center flex-col sm:flex-row md:mb-10"
          >
            {isLaunched ? (
              <ActionButton
                actionType={LogTypeIdEnum.buyOnAmazon}
                href={amazonListingLink}
                target="_blank"
              >
                Buy on Amazon
              </ActionButton>
            ) : (
              <ActionButton href="" target="" active={false}>
                Coming Soon...
              </ActionButton>
            )}

            <AnchorLink
              className="flex z-10 min-w-[165px]"
              href="#benefits"
              onClick={() => {
                setSelectedPage(SelectedPage.Benefits);
              }}
            >
              <a
                style={{ display: 'flex' }}
                className={`flex-1 items-center justify-center rounded-md bg-white min-w-[165px] max-w-[200px] text-center px-6 py-3 hover:bg-black hover:text-white hover:cursor-pointer z-10`}
              >
                Learn More
              </a>
            </AnchorLink>
          </motion.div>
        </div>
        {/* IMAGE */}
      </motion.div>
    </section>
  );
};

export default Home;
